import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
//import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet
import { Button, Icon, Header, Segment} from 'semantic-ui-react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { somaEnvase } from './../../_actions';

var format=require('format-number');
var myFormat = format({ decimalsSeparator:',',integerSeparator:'.'});

class Envase extends React.Component {
    constructor(props){
        super(props);
        this.state = {data:[]};
    }
    
    calcula(cell) {

        let data = this.state.data;
        for (var x = 0; x < data.length; x++) {
            console.log(data)

            if (data[x].id === cell._cell.row.data.id) {
                
                if (cell._cell.row.data.turmas_regime === '4T - (6X2)') {
                    cell._cell.row.data.hora_dia = 24;
                    cell._cell.row.data.dia_mes = 28;
                    cell._cell.row.data.mes_ano = 12;
                    cell._cell.row.data.disponibilidade = 0.969
                }
                else if (cell._cell.row.data.turmas_regime === '3T - (6X1)') {
                    cell._cell.row.data.hora_dia = 24;
                    cell._cell.row.data.dia_mes = 26;
                    cell._cell.row.data.mes_ano = 12;
                    
                    cell._cell.row.data.disponibilidade = 0.969
                }
                else if (cell._cell.row.data.turmas_regime === '2T - (6X1)') {
                    cell._cell.row.data.hora_dia = 16;
                    cell._cell.row.data.dia_mes = 26;
                    cell._cell.row.data.mes_ano = 12;
                    
                    cell._cell.row.data.disponibilidade = 0.9375
                }
                else if (cell._cell.row.data.turmas_regime === '1T - (6X1)') {
                    cell._cell.row.data.hora_dia = 8;
                    cell._cell.row.data.dia_mes = 26;
                    cell._cell.row.data.mes_ano = 12;
                    
                    cell._cell.row.data.disponibilidade = 0.875
                }
                else if (cell._cell.row.data.turmas_regime ==='3T - (6X2)') {
                    cell._cell.row.data.hora_dia = 24;
                    cell._cell.row.data.dia_mes = 22;
                    cell._cell.row.data.mes_ano = 12;
                    
                    cell._cell.row.data.disponibilidade = 0.969
                }
                else if (cell._cell.row.data.turmas_regime === '2T - (6X2)') {
                    cell._cell.row.data.hora_dia = 16;
                    cell._cell.row.data.dia_mes = 22;
                    cell._cell.row.data.mes_ano = 12;
                    
                    cell._cell.row.data.disponibilidade = 0.9375
                }
                else if (cell._cell.row.data.turmas_regime === '1T - (6X2)') {
                    cell._cell.row.data.hora_dia = 8;
                    cell._cell.row.data.dia_mes = 22;
                    cell._cell.row.data.mes_ano = 12;
                    
                    cell._cell.row.data.disponibilidade = 0.875
                }
                
                data[x].produtividade = cell._cell.row.data.eficiencia*cell._cell.row.data.disponibilidade;

                data[x].dia = cell._cell.row.data.velocidade_nominal*cell._cell.row.data.hora_dia*cell._cell.row.data.produtividade*cell._cell.row.data.volume/1000/100;

                data[x].mes = cell._cell.row.data.velocidade_nominal*cell._cell.row.data.hora_dia*cell._cell.row.data.produtividade*cell._cell.row.data.volume/1000/100*cell._cell.row.data.dia_mes;

                data[x].ano = cell._cell.row.data.velocidade_nominal*cell._cell.row.data.hora_dia*cell._cell.row.data.produtividade*cell._cell.row.data.volume/1000/100*cell._cell.row.data.dia_mes*cell._cell.row.data.mes_ano;

            }
        }

        this.setState({})
    }
    
    soma(values, data, calcParams){
        var calc = 0;
        values.forEach(function(value){
            calc = calc+value;
        });
        return myFormat(Math.round(calc));
    }
    addRow(){
        let data = this.state.data;
        let linha = 'LINHA '+(this.state.data.length+1);

        data.push({ 'id':Math.round(Math.random()*1000000),
                    'linha':linha,
                    'velocidade_nominal':1,
                    'volume':1,
                    'turmas_regime':'',
                    'hora_dia':1,
                    'dia_mes':28,
                    'mes_ano':12,
                    'eficiencia':0.92,
                    'disponibilidade':0.969,
                    'produtividade':0.89148,
                    
                    'dia': 0, 
                    'mes': 0, 
                    'ano': 0,
                    'img_cancel':'Excluir'
        });
                    
        this.setState({data:data});
    }
  render() {
        const {
              somaEnvase,
              data_envase
        } = this.props;
          
        somaEnvase(JSON.stringify(this.state.data));
        
  var columns = [
    { title: "Linha", frozen:true, field: "linha", width: 125, editor: "input", align: "center" },
    { title: "Velocidade Nominal", frozen:true, field: "velocidade_nominal", cellEdited:this.calcula.bind(this), editor: "number", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
    { title: "Volume", field: "volume", editor: "number", cellEdited:this.calcula.bind(this), align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
    { title: "Turmas / Regime", field: "turmas_regime", editor: "select",editorParams:{values:["4T - (6X2)", "3T - (6X1)", "2T - (6X1)","1T - (6X1)","3T - (6X2)","2T - (6X2)","1T - (6X2)"]}, cellEdited:this.calcula.bind(this), align: "center"},
    { title: "Hora/dia", field: "hora_dia", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Dia/Mês", field: "dia_mes", editor: "number", cellEdited:this.calcula.bind(this), align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Mês/Ano", field: "mes_ano", editor: "number", cellEdited:this.calcula.bind(this), align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Eficiência", field: "eficiencia", editor: "number", cellEdited:this.calcula.bind(this), align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:3}},
    { title: "Disponibilidade", field: "disponibilidade", editor: "number", cellEdited:this.calcula.bind(this), align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:3}},
    { title: "Produtividade", field: "produtividade",  align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:3}},
    {
        title: "Volume",
        align: "center",
        columns: [
              { title: "Dia", field: "dia", align: "center" , bottomCalc:this.soma, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Mês", field: "mes", align: "center" , bottomCalc:this.soma , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Ano", field: "ano", align: "center" , bottomCalc:this.soma, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}}
        ]
    },
    {
              title: "",
              field:"img_cancel",
              width: 50,
              align: "center",
              cellClick: function(e, cell) {
                  let data = this.state.data;
                      data = data.filter(function(item){
                          return item.id !== cell._cell.row.data.id
                      });
                      this.setState({data:data})
              }.bind(this)
          }

];
      
    return (
     <Segment raised style={{'padding':'0px', 'margin':'14px'}}>
             <Segment clearing attached='top' style={{'padding':'10px'}}>
                <Header as='h4' floated='left' style ={{'margin':'0px'}}>
                   Envase
                </Header>
                <Button floated='right' size='mini' icon color='green' onClick={this.addRow.bind(this)} labelPosition='left'><Icon name='plus' />Adicionar Linha</Button>  
            </Segment>
              <Segment attached='bottom' style={{'padding':'0px'}}>
                  <ReactTabulator
                    style={{fontSize:'10px', border:'0px', margin:'0px'}}
                    className={'very compact celled'}
                     data={this.state.data}
                     columns={columns}
                     tooltips={true}
                    layout={"fitData"}
                 />
             </Segment>
        </Segment>
    );
  }
}

const mapStateToProps = store => ({
  data_envase: store.somaAdega.data_envase
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ somaEnvase }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Envase);
