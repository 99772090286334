import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
//import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet
import { Button, Icon, Image, Header, Segment} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { somaBrassagem } from './../../_actions';

var format=require('format-number');
var myFormat = format({ decimalsSeparator:',',integerSeparator:'.'});


class Fabricacao extends React.Component {
    constructor(props){
        super(props);
        this.state = {data:[]};
    }
    
    
    calcula(cell) {

        let data = this.state.data;
        for (var x = 0; x < data.length; x++) {

            if (data[x].id === cell._cell.row.data.id) {
                data[x].diluicao = Math.round(cell._cell.row.data.mb / 11 * 1000) / 1000;
                
                var fabricos_dia = cell._cell.row.data.fabricos_dia;
                var capacidade = cell._cell.row.data.capacidade;
                var contracao = cell._cell.row.data.contracao;
                var diluicao = cell._cell.row.data.diluicao;
                
                var dia_mes = cell._cell.row.data.dia_mes;
                var mes_ano = cell._cell.row.data.mes_ano;
                
                data[x].dia = fabricos_dia*capacidade*contracao*diluicao;
                data[x].mes = fabricos_dia*capacidade*contracao*diluicao*dia_mes;
                data[x].ano = fabricos_dia*capacidade*contracao*diluicao*dia_mes*mes_ano;

            }
        }

        this.setState({})
        
    }
    
    soma(values, data, calcParams){
        var calc = 0;
        values.forEach(function(value){
            calc = calc+value;
        });
        return myFormat(Math.round(calc));
    }

    addRow(){
        let data = this.state.data;
        let sala = 'SALA '+(this.state.data.length+1);
        let diluicao = Math.round(16/11*1000)/1000;

        data.push({ 'id':Math.round(Math.random()*1000000),
                    'sala': sala, 
                    'capacidade': 1, 
                    'fabricos_dia': 1, 
                    'mb': 16, 
                    'contracao': 0.96, 
                    'diluicao': diluicao, 
                    'dia_mes': 28, 
                    'mes_ano': 12, 
                    'dia': 0, 
                    'mes': 0, 
                    'ano': 0,
                    'img_cancel':'Excluir'
        });
                    
        

        this.setState({data:data});
     
    }
    
  render() {
    const {
        somaBrassagem,
        data_fabricacao
    } = this.props;

     somaBrassagem(JSON.stringify(this.state.data));
    
    
      var columns = [
          { title: "Sala", field: "sala", width: 125, editor: "input", align: "center", frozen:true },
          { title: "Capacidade", field: "capacidade", frozen:true, editor: "number", align: "center", cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:true}},
          { title: "Fabricos / Dia", field: "fabricos_dia", editor: "number", align: "center", cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:true} },
          { title: "MB", field: "mb", editor: "number", align: "center", cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:true}},
          { title: "Contração", field: "contracao", editor: "number", align: "center", cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:3} },
          { title: "Diluição", field: "diluicao", align: "center", formatter:"money", formatterParams:{decimal:",",thousand:".",precision:4},headerTooltip:"Diluição = MB (Mosto Básico) / 11"},
          { title: "Dia / Mês", field: "dia_mes", editor: "number", align: "center", cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:true} },
          { title: "Mês / Ano", field: "mes_ano", editor: "number", align: "center", cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:true} },
          {
              title: "Volume",
              align: "center",
              columns: [
                  { title: "Dia", field: "dia", align: "center" , bottomCalc:this.soma, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0},headerTooltip:"Volume Dia = Capacidade x Fabricos por Dia x Contração x Diluição"},
                  { title: "Mês", field: "mes", align: "center" , bottomCalc:this.soma , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0},headerTooltip:"Volume Mês = Capacidade x Fabricos por Dia x Contração x Diluição x Dias por Mês"},
                  { title: "Ano", field: "ano", align: "center" , bottomCalc:this.soma , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0},headerTooltip:"Volume Ano = Capacidade x Fabricos por Dia x Contração x Diluição x Dias por Mês x Meses por Ano"}
              ]
          },
          {
              title: "",
              field:"img_cancel",
              width: 50,
              align: "center",
              cellClick: function(e, cell) {
                  let data = this.state.data;
                      data = data.filter(function(item){
                          return item.id !== cell._cell.row.data.id
                      });
                      this.setState({data:data});
              }.bind(this)
          }
      ];
       
    return (
       <Segment raised style={{'padding':'0px', 'margin':'14px'}}>
             <Segment clearing attached='top' style={{'padding':'10px'}}>
                <Header as='h4' floated='left' style ={{'margin':'0px'}}>
                   Brassagem
                </Header>
                <Button floated='right' size='mini' icon color='green' onClick={this.addRow.bind(this)} labelPosition='left'><Icon name='plus' />Adicionar Sala</Button>  
            </Segment>
              <Segment attached='bottom' style={{'padding':'0px'}}>
                  <ReactTabulator
                    style={{fontSize:'10px', border:'0px', margin:'0px'}}
                    className={'very compact celled'} 
                     data={this.state.data}
                     columns={columns}
                     tooltips={true}
                    layout={"fitData"}
                 />
             </Segment>
        </Segment>
    );
  }
}

const mapStateToProps = store => ({
  data_fabricacao: store.somaBrassagem.data_fabricacao
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ somaBrassagem }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Fabricacao);

