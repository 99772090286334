import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
//import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet
import { Header, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';

const columns = [
    { title: "Área", field: "area", width: 150, editor: "input" },
    {
        title: "Volume", align:"center",
        columns: [{ title: "Dia", field: "dia", editor: "input" , align: "center", formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
            { title: "Mês", field: "mes", align: "center", editor: "input" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
            { title: "Ano", field: "ano", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}}
        ]
    },

];

class Resumo extends React.Component {
    constructor(props){
        super(props);
        this.state = {data:[]};
    }
    
  render() {
      
    const { data_fabricacao, data_adega, data_filtracao, data_pressao, data_envase } = this.props;
    
    let dataj_fabricacao = eval(data_fabricacao);
    let dataj_adega = eval(data_adega);
    let dataj_filtracao = eval(data_filtracao);
    let dataj_pressao = eval(data_pressao);
    let dataj_envase = eval(data_envase);


    let total_fabricacao_dia = dataj_fabricacao.reduce( function( prevVal, elem ) {
            return prevVal + elem.dia;
        }, 0 );
    
    let total_fabricacao_mes = dataj_fabricacao.reduce( function( prevVal, elem ) {
            return prevVal + elem.mes;
        }, 0 );
        
    let total_fabricacao_ano = dataj_fabricacao.reduce( function( prevVal, elem ) {
            return prevVal + elem.ano;
        }, 0 );
        
    /////////////////////////////////////////////////////////////////////////////////////
        
    let total_adega_dia = dataj_adega.reduce( function( prevVal, elem ) {
            return prevVal + elem.dia;
        }, 0 );
    
    let total_adega_mes = dataj_adega.reduce( function( prevVal, elem ) {
            return prevVal + elem.mes;
        }, 0 );
        
    let total_adega_ano = dataj_adega.reduce( function( prevVal, elem ) {
            return prevVal + elem.ano;
        }, 0 ); 
        
    /////////////////////////////////////////////////////////////////////////////////////
        
    let total_filtracao_dia = dataj_filtracao.reduce( function( prevVal, elem ) {
            return prevVal + elem.dia;
        }, 0 );
    
    let total_filtracao_mes = dataj_filtracao.reduce( function( prevVal, elem ) {
            return prevVal + elem.mes;
        }, 0 );
        
    let total_filtracao_ano = dataj_filtracao.reduce( function( prevVal, elem ) {
            return prevVal + elem.ano;
        }, 0 ); 
        
    /////////////////////////////////////////////////////////////////////////////////////
        
    let total_pressao_dia = dataj_pressao.reduce( function( prevVal, elem ) {
            return prevVal + elem.dia;
        }, 0 );
    
    let total_pressao_mes = dataj_pressao.reduce( function( prevVal, elem ) {
            return prevVal + elem.mes;
        }, 0 );
        
    let total_pressao_ano = dataj_pressao.reduce( function( prevVal, elem ) {
            return prevVal + elem.ano;
        }, 0 ); 
        
    /////////////////////////////////////////////////////////////////////////////////////
        
    let total_envase_dia = dataj_envase.reduce( function( prevVal, elem ) {
            return prevVal + elem.dia;
        }, 0 );
    
    let total_envase_mes = dataj_envase.reduce( function( prevVal, elem ) {
            return prevVal + elem.mes;
        }, 0 );
        
    let total_envase_ano = dataj_envase.reduce( function( prevVal, elem ) {
            return prevVal + elem.ano;
        }, 0 ); 
        
    
    let data = [  {'area':'1 - Brassagem', 'dia':total_fabricacao_dia,'mes':total_fabricacao_mes,'ano':total_fabricacao_ano},
                  {'area':'2 - Adega', 'dia':total_adega_dia,'mes':total_adega_mes,'ano':total_adega_ano},
                  {'area':'3 - Filtração', 'dia':total_filtracao_dia,'mes':total_filtracao_mes,'ano':total_filtracao_ano},
                  {'area':'4 - Adega de Pressão', 'dia':total_pressao_dia,'mes':total_pressao_mes,'ano':total_pressao_ano},
                  {'area':'5 - Envase', 'dia':total_envase_dia,'mes':total_envase_mes,'ano':total_envase_ano}
    ]
    

    return (
    <Segment raised style={{'padding':'0px', 'margin':'14px'}}>
             <Segment clearing attached='top' style={{'padding':'10px'}}>
                <Header as='h4' floated='left' style ={{'margin':'0px'}}>
                   Resumo
                </Header>
            </Segment>
              <Segment attached='bottom' style={{'padding':'0px'}}>
                  <ReactTabulator
                    style={{fontSize:'10px', border:'0px', margin:'0px', width:'100%'}}
                    className={'very compact celled'}
                     data={data}
                     columns={columns}
                     tooltips={true}
                    layout={"fitData"}
                 />
             </Segment>
        </Segment>
    );
  }
}

const mapStateToProps = store => ({
  data_fabricacao: store.somaBrassagem.data_fabricacao,
  data_adega:store.somaAdega.data_adega,
  data_filtracao:store.somaFiltracao.data_filtracao,
  data_pressao:store.somaPressao.data_pressao,
  data_envase:store.somaEnvase.data_envase
});

export default connect(mapStateToProps)(Resumo);
