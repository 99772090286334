import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet

import Resumo from './resumo';
import Fabricacao from './fabricacao';
import Adega from './adega';
import Filtracao from './filtracao';
import Pressao from './pressao';
import Envase from './envase';

class Tabela extends React.Component {
  render() {
    return (
        <div style={{marginBottom:'55px'}}>
            <Resumo/>
            <Fabricacao/>
            <Adega/>
            <Filtracao/>
            <Pressao/>
            <Envase/>
        </div>
    );
  }
}

export default Tabela;
