import { somaBrassagem } from './somaBrassagem';
import { somaAdega } from './somaAdega';
import { somaFiltracao } from './somaFiltracao';
import { somaPressao } from './somaPressao';
import { somaEnvase } from './somaEnvase';

import { combineReducers } from 'redux';
export const Reducers = combineReducers({
  somaBrassagem: somaBrassagem,
  somaAdega:somaAdega,
  somaFiltracao:somaFiltracao,
  somaPressao:somaPressao,
  somaEnvase:somaEnvase
});