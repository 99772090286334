import React from 'react';
import {Image, Menu, Button, Icon } from 'semantic-ui-react';

import img from './../sim_fab_icon.png';

class Header extends React.Component {
  render() {
    return (
        <Menu fixed='top'>
            <Menu.Item as='div' header style={{width:'100%'}}>
              <Button size='huge' style={{borderRadius:'0px', backgroundColor:'white'}}><Icon name='align justify' /></Button>
              <Image size='mini' src={img} style={{ marginRight: '0em', width:'175px' }} />
            </Menu.Item>
        </Menu>
    );
  }
}

export default Header;
