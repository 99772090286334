import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'semantic-ui-react';

import './App.css';

import  Header  from './ui/header';
import  Footer  from './ui/footer';
import  AdicionarAnalise from './ui/adicionaranalise';

import Tabelas from './tabelas';

//const url = "ws://3.213.201.161:3002";
//var w = new WebSocket(url);

class App extends React.Component {
  state = {
    inputValue: ''
  }
  
  inputChange = event => {
    this.setState({
      inputValue: event.target.value
    })
  }

  enviar(){
   // w.send(document.getElementById('t').value);
  }
  
  componentDidMount() {
    
   
  }

  componentWillUnmount() {
    
  }

  render() {
    const { clickButton,newValue } = this.props;
    const { inputValue } = this.state;

    return (
       <div>
         <Header/>
          <div style={{ marginTop: '7em', marginLeft:'10px' }}>
            <Tabelas/>
          </div>
      
       </div>
    );
  }
}

//O método mapStateToProps transforma um trecho do estado da Store em uma prop utilizável pelo componente, com o nome newValue.
const mapStateToProps = store => ({
  //newValue: store.clickState.newValue
});

//O método mapDispatchToProps serve para converter a Action Creator clickButton que criamos no arquivo src/actions/index.js em uma prop para o componente.
//O método bindActionCreators facilita este trabalho.


//Conecta o componente ao Redux
export default connect(mapStateToProps)(App);
