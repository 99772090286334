import { UPDATE_FABRICACAO, UPDATE_ADEGA, UPDATE_FILTRACAO, UPDATE_PRESSAO, UPDATE_ENVASE } from './actionTypes';

export const somaBrassagem = value => ({
  type: 'UPDATE_FABRICACAO',
  data_fabricacao: value
});

export const somaAdega = value => ({
  type: 'UPDATE_ADEGA',
  data_adega: value
});

export const somaFiltracao = value => ({
  type: 'UPDATE_FILTRACAO',
  data_filtracao: value
});

export const somaPressao = value => ({
  type: 'UPDATE_PRESSAO',
  data_pressao: value
});

export const somaEnvase = value => ({
  type: 'UPDATE_ENVASE',
  data_envase: value
});