import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
//import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet
import { Button, Icon, Header, Segment} from 'semantic-ui-react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { somaFiltracao } from './../../_actions';

var format=require('format-number');
var myFormat = format({ decimalsSeparator:',',integerSeparator:'.'});



class Filtracao extends React.Component {
    constructor(props){
        super(props);
        this.state = {data:[]};
    }
    
    calcula(cell) {

        let data = this.state.data;
        for (var x = 0; x < data.length; x++) {

            if (data[x].id === cell._cell.row.data.id) {
               

                data[x].dia = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.eficiencia*cell._cell.row.data.hora_dia;

                data[x].mes = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.eficiencia*cell._cell.row.data.hora_dia*cell._cell.row.data.dia_mes;

                data[x].ano = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.eficiencia*cell._cell.row.data.hora_dia*cell._cell.row.data.dia_mes*cell._cell.row.data.mes_ano;

            }
        }

        this.setState({})
    }
    
    soma(values, data, calcParams){
        var calc = 0;
        values.forEach(function(value){
            calc = calc+value;
        });
        return myFormat(Math.round(calc));
    }  
    
    
    addRow(){
        let data = this.state.data;
        let filtro = 'FILTRO '+(this.state.data.length+1);

        data.push({ 'id':Math.round(Math.random()*1000000),
                    'filtro': filtro,
                    'capacidade':1,
                    'qtd':1,
                    'eficiencia':0.9,
                    'hora_dia':20,
                    'dia_mes':28,
                    'mes_ano':11.5,
                    'dia': 0, 
                    'mes': 0, 
                    'ano': 0,
                    'img_cancel':'Excluir'
        });
                    
        this.setState({data:data});
    }
    
  render() {
      
        const {
              somaFiltracao,
              data_filtracao
          } = this.props;
          
        somaFiltracao(JSON.stringify(this.state.data));
      
var columns = [
    { title: "Filtro", frozen:true, field: "filtro", width: 125, editor: "input", align: "center" },
    { title: "Capacidade", frozen:true, field: "capacidade",  editor: "number", align: "center" , cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
    { title: "Qtd.", field: "qtd", editor: "number", align: "center" , cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
    { title: "Efi", field: "eficiencia",  editor: "number", align: "center" , cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Hora / Dia", field: "hora_dia",  editor: "number", align: "center" , cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
    { title: "Dia / Mês", field: "dia_mes",  editor: "number", align: "center" , cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
    { title: "Mês / Ano", field: "mes_ano",  editor: "number", align: "center" , cellEdited:this.calcula.bind(this),formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
     {
        title: "Volume",
        align: "center",
        columns: [
              { title: "Dia", field: "dia", align: "center" , bottomCalc:this.soma,formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Mês", field: "mes", align: "center" , bottomCalc:this.soma ,formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Ano", field: "ano", align: "center" , bottomCalc:this.soma,formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}}
        ]
    },
    {
              title: "",
              field:"img_cancel",
              width: 50,
              align: "center",
              cellClick: function(e, cell) {
                  let data = this.state.data;
                      data = data.filter(function(item){
                          return item.id !== cell._cell.row.data.id
                      });
                      this.setState({data:data})
              }.bind(this)
          }

    ];
    return (
      <Segment raised style={{'padding':'0px', 'margin':'14px'}}>
             <Segment clearing attached='top' style={{'padding':'10px'}}>
                <Header as='h4' floated='left' style ={{'margin':'0px'}}>
                   Filtração
                </Header>
                <Button floated='right' size='mini' icon color='green' onClick={this.addRow.bind(this)} labelPosition='left'><Icon name='plus' />Adicionar Filtro</Button>  
            </Segment>
              <Segment attached='bottom' style={{'padding':'0px'}}>
                  <ReactTabulator
                    style={{fontSize:'10px', border:'0px', margin:'0px'}}
                    className={'very compact celled'}
                     data={this.state.data}
                     columns={columns}
                     tooltips={true}
                    layout={"fitData"}
                 />
             </Segment>
        </Segment>
    );
  }
}


const mapStateToProps = store => ({
  data_filtracao: store.somaFiltracao.data_filtracao
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ somaFiltracao }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Filtracao);

