import { UPDATE_PRESSAO } from '../_actions/actionTypes';

const initialState = {
  data_pressao: []
};
export const somaPressao = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PRESSAO':
      return {
        ...state,
        data_pressao: action.data_pressao
      };
    default:
      return state;
  }
};