import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
//import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet
import { Button, Icon, Header, Segment} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { somaAdega } from './../../_actions';

var format=require('format-number');
var myFormat = format({ decimalsSeparator:',',integerSeparator:'.'});


class Adega extends React.Component {
    constructor(props){
        super(props);
        this.state = {data:[]};
    }
    
    calcula(cell) {

        let data = this.state.data;
        for (var x = 0; x < data.length; x++) {

            if (data[x].id === cell._cell.row.data.id) {
               
                
                var fermentacao_enchimento = cell._cell.row.data.fermentacao_enchimento;
                var fermentacao_fermentacao = cell._cell.row.data.fermentacao_fermentacao;
                var fermentacao_resfriamento = cell._cell.row.data.fermentacao_resfriamento;
                var fermentacao_transferencia = cell._cell.row.data.fermentacao_transferencia;
                var fermentacao_cip = cell._cell.row.data.fermentacao_cip;
                
                var maturacao_cip =cell._cell.row.data.maturacao_cip;
                var maturacao_enchimento = cell._cell.row.data.maturacao_enchimento;
                var maturacao_filtracao = cell._cell.row.data.maturacao_filtracao;
                var maturacao_maturacao = cell._cell.row.data.maturacao_maturacao;
                
                data[x].utilizacao_mes = 30 * 24 / (     fermentacao_enchimento +
                                                         fermentacao_fermentacao + 
                                                         fermentacao_resfriamento +
                                                         fermentacao_transferencia +
                                                         fermentacao_cip +
                                                         maturacao_cip +
                                                         maturacao_enchimento +
                                                         maturacao_filtracao +
                                                         maturacao_maturacao );

                data[x].diluicao =  (cell._cell.row.data.mb_fabricacao-cell._cell.row.data.rendimento_extrato)/cell._cell.row.data.mb_envase ;
                
                data[x].dia = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.utilizacao_mes*cell._cell.row.data.schwand*cell._cell.row.data.diluicao/30;

                data[x].mes = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.utilizacao_mes*cell._cell.row.data.schwand*cell._cell.row.data.diluicao;

                data[x].ano = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.utilizacao_mes*cell._cell.row.data.schwand*cell._cell.row.data.diluicao*11.5;

                
                
               
            }
        }

        this.setState({})
        
    }
    
    soma(values, data, calcParams){
        var calc = 0;
        values.forEach(function(value){
            calc = calc+value;
        });
        return myFormat(Math.round(calc));
    }
    
    addRow(){
        let data = this.state.data;
        let tanque = 'TQ '+(this.state.data.length+1);

        data.push({ 'id':Math.round(Math.random()*1000000),
                    'tipo':'PILSEN',
                    'tanque':tanque,
                    'capacidade':1,
                    'qtd':1,
                    
                    'fermentacao_enchimento':1,
                    'fermentacao_fermentacao':96,
                    'fermentacao_resfriamento':1,
                    'fermentacao_transferencia':1,
                    'fermentacao_cip':3,
                    
                    'maturacao_enchimento':1,
                    'maturacao_maturacao':168,
                    'maturacao_filtracao':1,
                    'maturacao_cip':3,
                    
                    'utilizacao_mes':1,
                    'schwand':0.96,
                    'mb_fabricacao':16,
                    'rendimento_extrato':1.2,
                    'mb_envase':11,
                    'diluicao':1,
                    
                    'dia': 0, 
                    'mes': 0, 
                    'ano': 0,
                    'img_cancel':'Excluir'
        });
                    
        this.setState({data:data});
    }
  render() { 
          const {
              somaAdega,
              data_adega
          } = this.props;
          
        somaAdega(JSON.stringify(this.state.data));
          
          
var columns = [
    { title: "Tipo", frozen:true, field: "tipo", width: 125, editor: "select", align: "center", editorParams:{values:["PILSEN", "GOLD/LAGER", "CIDADE IMPERIAL"]} },
    { title: "Tanque", frozen:true, field: "tanque", editor: "input", align: "center" , headerVertical:true},
    { title: "Capacidade", field: "capacidade", editor: "number", align: "center" , headerVertical:true, cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
    { title: "Qtd.", field: "qtd", editor: "number", align: "center" , headerVertical:true, cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
    
    {
        title: "Fermentação em horas",
        align: "center",
        columns: [
            { title: "Ench", field: "fermentacao_enchimento", editor: "number" , cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
            { title: "Ferm", field: "fermentacao_fermentacao", align: "center", editor: "number" , cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
            { title: "Resf", field: "fermentacao_resfriamento", align: "center" , editor: "number", cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
            { title: "Trans", field: "fermentacao_transferencia", align: "center" , editor: "number", cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
            { title: "CIP", field: "fermentacao_cip", align: "center" , editor: "number", cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}}
        ]
    },
    
    {
        title: "Maturação em horas",
        align: "center",
        columns: [
            { title: "Ench", field: "maturacao_enchimento", editor: "number" , cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
            { title: "Mat", field: "maturacao_maturacao", align: "center", editor: "number" , cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
            { title: "Filtr", field: "maturacao_filtracao", align: "center" , editor: "number", cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}},
            { title: "CIP", field: "maturacao_cip", align: "center", editor: "number" , cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}}
        ]
    },

    { title: "Utl/Mês.", field: "utilizacao_mes", align: "center", headerVertical:true, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:3} },
    { title: "Schwand.", field: "schwand", editor: "number", align: "center" , headerVertical:true, cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "MB Fab.", field: "mb_fabricacao", editor: "number", align: "center" , headerVertical:true, cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Rend. Extr.", field: "rendimento_extrato", editor: "number", align: "center", headerVertical:true , cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "MB Env.", field: "mb_envase", editor: "number", align: "center" , headerVertical:true, cellEdited:this.calcula.bind(this), formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Diluição", field: "diluicao", align: "center" , headerVertical:true, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},

    
    {
        title: "Volume",
        align: "center",
        columns: [
              { title: "Dia", field: "dia", align: "center" , bottomCalc:this.soma, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Mês", field: "mes", align: "center" , bottomCalc:this.soma , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Ano", field: "ano", align: "center" , bottomCalc:this.soma, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}}
        ]
    },
    {
              title: "",
              field:"img_cancel",
              width: 50,
              align: "center",
              cellClick: function(e, cell) {
                  let data = this.state.data;
                      data = data.filter(function(item){
                          return item.id !== cell._cell.row.data.id
                      });
                      this.setState({data:data})
              }.bind(this)
          }
];
      
    return (
      <Segment raised style={{'padding':'0px', 'margin':'14px'}}>
             <Segment clearing attached='top' style={{'padding':'10px'}}>
                <Header as='h4' floated='left' style ={{'margin':'0px'}}>
                   Adega
                </Header>
                <Button floated='right' size='mini' icon color='green' onClick={this.addRow.bind(this)} labelPosition='left'><Icon name='plus' /> Adicionar Tanque</Button>  
            </Segment>
              <Segment attached='bottom' style={{'padding':'0px'}}>
                  <ReactTabulator
                    style={{fontSize:'10px', border:'0px', margin:'0px'}}
                    className={'very compact celled'}
                     data={this.state.data}
                     columns={columns}
                     tooltips={true}
                    layout={"fitData"}
                 />
             </Segment>
        </Segment>
    );
  }
}


const mapStateToProps = store => ({
  data_adega: store.somaAdega.data_adega
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ somaAdega }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Adega);
