import React from 'react';
import { Button, Checkbox, Form, Input, Radio, Select, TextArea } from 'semantic-ui-react'

const options = [
  { key: '01', text: 'Janeiro', value: '01' },
  { key: '02', text: 'Fevereiro', value: '02' },
  { key: '03', text: 'Março', value: '03' },
]

const optionsanos = [ {key: '2019', text: '2019', value: '2019' }];

const optionsmeses=[  { key: '01', text: 'Janeiro', value: '01' },
                      { key: '02', text: 'Fevereiro', value: '02' },
                      { key: '03', text: 'Março', value: '03' }]

const optionsdias = [ { key: '01', text: '01', value: '01' },
                      { key: '02', text: '02', value: '02' },
                      { key: '03', text: '03', value: '03' },]

const anos = <Form.Field control={Select} label='Ano' options={optionsanos} placeholder='Ano' />;
const meses =<Form.Field control={Select} label='Mês' options={optionsmeses} placeholder='Mês' />;
const dias =<Form.Field control={Select} label='Dia' options={optionsdias} placeholder='Dia' />;


const periodos = [    { key: '01', text: 'Diário', value: 'diario' },
                      { key: '02', text: 'Mensal', value: 'mensal' },
                      { key: '03', text: 'Anual', value: 'anual' }];



class AdicionarAnalise extends React.Component {
    constructor(props){
        super(props);
        this.state = {periodo:''};
        
    }

  atualizaValor(e, {name, value }){
      this.setState({ [name]:value })
  };

  render() {
     const { value } = this.state
     let componentemeses = this.state.periodo === 'anual' ? '' : meses ;
     let componentedias = this.state.periodo === 'diario' || this.state.periodo === ''  ? dias : '' ;
         
     return (
       <div>
        <Form>
        <Form.Group widths='equal'>
          <Form.Field control={Input} label='Indicador' placeholder='Indicador' />
          <Form.Field control={Select} label='Período de Análise' placeholder='Período de Análise' name='periodo' options={periodos} onChange={this.atualizaValor.bind(this)}/>
            {anos }{componentemeses}{componentedias}
        </Form.Group>
        <Form.Field control={TextArea} label='Análise' placeholder='Digite sua análise...' />
        <Form.Field control={Checkbox} label='Enviar Notificação' />
        <Button style={{backgroundColor:'#fec001'}}>Enviar Análise</Button>
      </Form>
      </div>
    );
  }
}

export default AdicionarAnalise;
