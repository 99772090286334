import { UPDATE_ENVASE } from '../_actions/actionTypes';

const initialState = {
  data_envase: []
};
export const somaEnvase = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ENVASE':
      return {
        ...state,
        data_envase: action.data_envase
      };
    default:
      return state;
  }
};