import React from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
//import "tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css"; //import Tabulator stylesheet
import { Button, Icon, Header, Segment} from 'semantic-ui-react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { somaPressao } from './../../_actions';

var format=require('format-number');
var myFormat = format({ decimalsSeparator:',',integerSeparator:'.'});

class Pressao extends React.Component {
    constructor(props){
        super(props);
        this.state = {data:[]};
    }
    
    
    calcula(cell) {

        let data = this.state.data;
        for (var x = 0; x < data.length; x++) { 

            if (data[x].id === cell._cell.row.data.id) {
                
                data[x].ciclo = (cell._cell.row.data.encher+cell._cell.row.data.homogenizar+cell._cell.row.data.analisar+cell._cell.row.data.co2+cell._cell.row.data.poisio+cell._cell.row.data.envasar+cell._cell.row.data.cip)/60/24;
                
               data[x].utilizacao_mes = 30/((cell._cell.row.data.encher+cell._cell.row.data.homogenizar+cell._cell.row.data.analisar+cell._cell.row.data.co2+cell._cell.row.data.poisio+cell._cell.row.data.envasar+cell._cell.row.data.cip)/60/24);

    
                data[x].dia = (cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.utilizacao_mes)/cell._cell.row.data.dia_mes;

                data[x].mes = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.utilizacao_mes;

                data[x].ano = cell._cell.row.data.capacidade*cell._cell.row.data.qtd*cell._cell.row.data.utilizacao_mes*cell._cell.row.data.mes_ano;

            }
        }

        this.setState({})
    }
    
    soma(values, data, calcParams){
        var calc = 0;
        values.forEach(function(value){
            calc = calc+value;
        });
        return myFormat(Math.round(calc));
    }
    addRow(){
        let data = this.state.data;
        let tanque = 'TQ PRESSÃO '+(this.state.data.length+1);

        data.push({ 'id':Math.round(Math.random()*1000000),
                    'tipo':'PILSEN',
                    'tanque':tanque,
                    'capacidade':1,
                    'qtd':1,
                    'encher':307,
                    'analisar':30,
                    'homogenizar':40,
                    'co2':5,
                    'poisio':1140,
                    'envasar':654,
                    'cip':39,
                    'ciclo':1,
                    'utilizacao_mes':1,
                    'dia_mes':30,
                    'mes_ano':12,

                    'dia': 0, 
                    'mes': 0, 
                    'ano': 0,
                    'img_cancel':'Excluir'
        });
                    
        this.setState({data:data});
    }
    
  render() {
      
        const {
              somaPressao,
              data_pressao
          } = this.props;
          
        somaPressao(JSON.stringify(this.state.data));
      
      var columns = [
    { title: "Tipo", frozen:true, field: "tipo", width: 125, editor: "select", align: "center", editorParams:{values:["PILSEN", "GOLD/LAGER", "CIDADE IMPERIAL"]} },
    { title: "Tanque", frozen:true, headerVertical:true, field: "tanque", editor: "input", align: "center" },
    { title: "Capacidade", frozen:true, headerVertical:true, field: "capacidade", editor: "number", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
    { title: "Qtd.", headerVertical:true, field: "qtd", editor: "number", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
    {
        title: "Ciclo de Utilização em minutos",
        align: "center",
        columns: [
            { title: "Ench",  field: "encher", editor: "number" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
            { title: "Homog", field: "homogenizar", align: "center", editor: "number" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
            { title: "Ana", field: "analisar", align: "center" , editor: "number", formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
            { title: "CO2", field: "co2", align: "center" , editor: "number", formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
            { title: "Pois", field: "poisio", align: "center" , editor: "number", formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
            { title: "Env", field: "envasar", align: "center" , editor: "number", formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)},
            { title: "CIP", field: "cip", align: "center" , editor: "number", formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}, cellEdited:this.calcula.bind(this)}

        ]
    },
    { title: "Ciclo / Dia", headerVertical:true, field: "ciclo", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Util. Mês", headerVertical:true, field: "utilizacao_mes", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:2}},
    { title: "Dia/Mês", headerVertical:true, field: "dia_mes", editor: "number", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}, cellEdited:this.calcula.bind(this)},
    { title: "Mês/Ano", headerVertical:true, field: "mes_ano", editor: "number", align: "center" , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:1}, cellEdited:this.calcula.bind(this)},
    {
        title: "Volume",
        align: "center",
        columns: [
              { title: "Dia", field: "dia", align: "center" , bottomCalc:this.soma, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Mês", field: "mes", align: "center" , bottomCalc:this.soma , formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}},
              { title: "Ano", field: "ano", align: "center" , bottomCalc:this.soma, formatter:"money", formatterParams:{decimal:",",thousand:".",precision:0}}
        ]
    },
    {
              title: "",
              field:"img_cancel",
              width: 50,
              align: "center",
              cellClick: function(e, cell) {
                  let data = this.state.data;
                      data = data.filter(function(item){
                          return item.id !== cell._cell.row.data.id
                      });
                      this.setState({data:data})
              }.bind(this)
          }

];
    return (
        <Segment raised style={{'padding':'0px', 'margin':'14px'}}>
             <Segment clearing attached='top' style={{'padding':'10px'}}>
                <Header as='h4' floated='left' style ={{'margin':'0px'}}>
                   Adega de Pressão
                </Header>
                <Button floated='right' size='mini' icon color='green' onClick={this.addRow.bind(this)} labelPosition='left'><Icon name='plus' />Adicionar Tanque</Button>  
            </Segment>
              <Segment attached='bottom' style={{'padding':'0px'}}>
                  <ReactTabulator
                    style={{fontSize:'10px', border:'0px', margin:'0px'}}
                    className={'very compact celled'}
                     data={this.state.data}
                     columns={columns}
                     tooltips={true}
                    layout={"fitData"}
                 />
             </Segment>
        </Segment>
    );
  }
}

const mapStateToProps = store => ({
  data_pressao: store.somaPressao.data_pressao
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ somaPressao }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Pressao);


