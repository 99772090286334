import React from 'react';
import { Menu, Button, Icon } from 'semantic-ui-react';

class Footer extends React.Component {
  render() {
    return (
        <Menu fixed='bottom' style={{backgroundColor:'rgb(0,176,80)', border:'0px'}}>
          <div    style={{ textAlign: 'center', width: '100%'}}>
            <Button.Group icon   style={{ textAlign: 'center', width: '100%'}}>
              <Button size='huge' basic style={{borderRadius:'0px'}}>
                <Icon name='home' />
              </Button>
              <Button size='huge' basic style={{borderRadius:'0px'}}>
                <Icon name='save' />
              </Button>
              <Button size='huge' basic style={{borderRadius:'0px'}}>
                <Icon name='plus' />
              </Button>
              <Button size='huge' basic style={{borderRadius:'0px'}}>
                <Icon name='setting' />
              </Button>
            </Button.Group>
          </div>
        </Menu>
 
    );
  }
}

export default Footer;
