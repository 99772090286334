import { UPDATE_FABRICACAO } from '../_actions/actionTypes';

const initialState = {
  data_fabricacao: []
};
export const somaBrassagem = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_FABRICACAO':
      return {
        ...state,
        data_fabricacao: action.data_fabricacao
      };
    default:
      return state;
  }
};