import { UPDATE_ADEGA } from '../_actions/actionTypes';

const initialState = {
  data_adega: []
};
export const somaAdega = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ADEGA':
      return {
        ...state,
        data_adega: action.data_adega
      };
    default:
      return state;
  }
};